import { defineStore } from 'pinia';
import axios from 'axios';

export const useMe = defineStore('me', {
  state: () => ({
    token: null,
    user: null,
    isLogged: null,
  }),

  actions: {
    async getMe() {
        const response = await axios.get('/api/me', {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.user = response.data;
        this.isLogged = true;
    },

    clearState() {
      this.token = null;
      this.user = null;
      this.isLogged = null;
    }
  }
});

/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from '@/Support/plugins/vuetify.js'
import pinia from '@/Support/store/index.js'
import router from '@/Support/router/index.js'

export function registerPlugins (app) {
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
}

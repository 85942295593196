import { auth } from '@/Support/router/guards.js';

export default {
  path: '/',
  component: () => import('@/Support/Components/MainAppBar.vue'),
  beforeEnter: auth,
  children: [
    {
      path: '/painel',
      name: 'dashboard',
      component: () => import('@/App/DashBoard/DashBoardIndex.vue'),
    },
    {
      path: '/funcionarios',
      name: 'employees',
      component: () => import('@/App/Registrations/Employees/Views/EmployeesIndex.vue'),
    },
    {
      path: '/tamanhos',
      name: 'sizes',
      component: () => import('@/App/Registrations/Sizes/Views/SizesIndex.vue'),
    },
    {
      path: '/categorias',
      name: 'categories',
      component: () => import('@/App/Registrations/Categories/Views/CategoryIndex.vue'),
    },
    {
      path: '/ingredientes',
      name: 'ingredients',
      component: () => import('@/App/Registrations/Ingredients/Views/IngredientsIndex.vue'),
    },
    {
      path: '/produtos',
      name: 'products',
      component: () => import('@/App/Registrations/Products/Views/ProductsIndex.vue'),
    },
    {
      path: '/status',
      name: 'statuses',
      component: () => import('@/App/Registrations/Statuses/Views/StatusesIndex.vue'),
    },
  ]
};

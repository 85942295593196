// Composables
import { createRouter, createWebHistory } from 'vue-router'
import MainRoutes from '@/Support/router/MainRoutes'
import AuthRoutes from '@/Support/router/AuthRoutes'
import { useAuth } from '@/Support/store/auth'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/Support/Views/NotFound.vue')
    },
    MainRoutes,
    ...AuthRoutes,
  ]
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuth()
  try {
    await authStore.sanctum()
  } catch (error) {
    console.error('Erro na autenticação:', error)
  }
  next()
});

export default router;
import {redirectIfAuthenticated} from '@/Support/router/guards';

export default [
  {
    path: '/login',
    component: () => import('@/App.vue'),
    beforeEnter: redirectIfAuthenticated,
    children: [
      {
        name: 'login',
        path: '',
        component: () => import('@/App/Auth/AuthLogin.vue'),
      },
    ],
  },
];
import { defineStore } from 'pinia';
import { useMe } from '@/Support/store/me';
import axios from 'axios';

export const useAuth = defineStore('auth', {
  state: () => ({}),

  actions: {
    async sanctum() {
        await axios.get('sanctum/csrf-cookie');
    },

    async login(email, password) {
      const meStore = useMe()

      try {
        if (meStore.isLogged) {
          return Promise.resolve();
        }
        
        await axios.post('api/login', {
          email, password
        }).then(response => {
          meStore.token = response.data.token.plainTextToken
        })
      } catch (error) {
        console.error(error)
      } finally {
        meStore.getMe();
      }
    },

    async logout() {
      try {
        const meStore = useMe();

        await axios.delete('/api/logout', {
          headers: {
            Authorization: `Bearer ${meStore.token}`,
          },
        });
        meStore.clearState();

      } catch (error) {
        console.error('Erro ao fazer logout:', error);
      }
    }
  },
  
  getters: {
    user() {
      const meStore = useMe()
      return meStore.user
    }
  }
})

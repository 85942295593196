// src/Support/axios.js
import axios from 'axios';
import { useMe } from '@/Support/store/me';

// Configuração global do Axios
axios.defaults.baseURL = import.meta.env.VITE_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

// Adicionar o interceptor para incluir o token Bearer
axios.interceptors.request.use(
  (config) => {
    const meStore = useMe();
    const token = meStore.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;

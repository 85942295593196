import { registerPlugins } from '@/Support/plugins'
import App from './App.vue'
import { createApp } from 'vue'
import '@/Support/axios/index.js'
import { useMe } from '@/Support/store/me';

// Cria a instância da aplicação
const app = createApp(App)

// Registra plugins
registerPlugins(app)

// Monta a aplicação primeiro
app.mount('#app')

// Busca os dados do usuário depois de montar a aplicação
const meStore = useMe()
meStore.getMe().catch((error) => {
  console.error('Erro ao buscar os dados do usuário:', error)
})